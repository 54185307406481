import React from "react"
import { HeroSectionWrapper } from "./HeroSection.styles"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"

const HeroSection = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "POL" }
          childImageSharp: { fluid: { originalName: { eq: "BG.png" } } }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const backgroundImage = data.allFile.edges[0] && data.allFile.edges[0].node.childImageSharp.fluid;
  return (
    <HeroSectionWrapper
      Tag="section"
      fluid={backgroundImage}
    >
      <Container className="h-100 d-flex flex-column justify-content-center">
        <Row>
          <Col md={6}>
            <h1 className="secondary-blue-2-text">How does a Payment Orchestration Platform work?</h1>
            <p className="text-white">
              The goal of any payment solution is a simple one: maximise
              conversion rates and limiting costs whilst making life as easy as
              possible for our merchants and their customers. This can be
              achieved by integrating the optimum providers globally through a
              single provider that will continue to audit your set-up to
              futureproof your payments stack.
            </p>
          </Col>
        </Row>
      </Container>
    </HeroSectionWrapper>
  )
}

export default HeroSection
