import React from "react"
import { PaymentOrchLayerSectionWrapper } from "./PaymentOrchLayer.styles"
import { Container, Row, Col } from "react-bootstrap"
import HeadingWithBg from "../../Global/Common/HeadingWithBg/HeadingWithBg"
import { POLSteps } from "../../../data/paymentOrchPage.data"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const PaymentOrchLayerSection = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "POL" }
          childImageSharp: { fluid: { originalName: { eq: "POL" } } }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const polImage = data.allFile.edges[0] && data.allFile.edges[0].node.childImageSharp.fluid;
  return (
    <PaymentOrchLayerSectionWrapper className="pt-5 pb-5">
      <Container className="mt-5 mb-5">
        <HeadingWithBg text="Payment Orchestrational Layer" />
        <ul className="mt-5 mb-5">
          {POLSteps.sort((a, b) => a.number - b.number).map((point) => (
            <li className="mb-4 poppins primary-blue-text">{point.text}</li>
          ))}
        </ul>
        <Row className="justify-content-center mt-5">
          <Col md={8}>
            <Img fluid={polImage} />
          </Col>
        </Row>
      </Container>
    </PaymentOrchLayerSectionWrapper>
  )
}

export default PaymentOrchLayerSection
