import React, { useState } from "react"
import { FormSectionWrapper } from "./FormSection.style"
import { Container, Form, Row, Col } from "react-bootstrap"
import HeadingWithBg from "../../Global/Common/HeadingWithBg/HeadingWithBg"
import ApInput from "../../Global/Common/ApInput/ApInput"
import ApButton from "../../Global/Common/ApButton/ApButton"
import { Link } from "gatsby"

const FormSection = () => {
  const [submitMessage, setSubmitMessage] = useState("");
  function encode(data) {
    const formData = new FormData()
    for (const key of Object.keys(data)) {
      formData.append(key,data[key]);
    }
    return formData;
  }
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        email: form.email.value,
        'first-name': form['first-name'].value,
        'last-name': form['last-name'].value,
        subject: form.subject.value,
        message: form.message.value,
        "got-ya": form["got-ya"].value,
      })
    })
    .then(() => setSubmitMessage("Thank you!"))
    .catch((error) =>setSubmitMessage("Sorry! something went wrong!"))
  }
  return (
    <FormSectionWrapper>
      <Container className="pt-5 pb-5">
        <HeadingWithBg text="Find Out More" />
        <h2 className="mt-4">We'd love to hear from you</h2>
        <p>
          Please fill in the form provided on this page and we'll get back to
          you as soon as possible.
          <br />
          For more information on our Payment Orchestration Platform fill in the form
          to receive our <Link to="#"> POL Whitepaper</Link>
        </p>

        <Form
          name="payment-orchestration-lead"
          method="POST"
          data-netlify="true"
          netlify-honeypot="got-ya"
          onSubmit={handleSubmit}
        >
          <input
            type="hidden"
            name="form-name"
            value="payment-orchestration-lead"
          />
            <label style={{visibility: "hidden"}}>Don’t fill this out if you're human: <input name="got-ya" /></label>

          <Row className="align-items-end">
            <Col md={6}>
              <ApInput
                label="Name"
                type="text"
                supportText="First Name"
                name="first-name"
                required={true}
              />
            </Col>
            <Col md={6}>
              <ApInput type="text" supportText="Last Name" name="last-name" />
            </Col>
          </Row>
          <ApInput label="Email" type="email" required={true} name="email" />
          <ApInput label="Subject" type="text" required={true} name="subject" />
          <ApInput
            label="Message"
            type="text"
            as="textarea"
            required={true}
            name="message"
          />
          <div className="text-center mt-5">
            <p>{submitMessage}</p>
            <ApButton variant="greenTwo" type="submit">
              Submit
            </ApButton>
          </div>
        </Form>
      </Container>
    </FormSectionWrapper>
  )
}

export default FormSection
