import styled from "styled-components"
import { Form } from "react-bootstrap"
import { colors } from "../../../../utils/styles";

export const StyledInput = styled(Form.Group)`
  font-weight: 400;
  input,
  textarea {
    background-color: #ecedee;
  }
  .text-muted,
  label {
    color: ${colors.primaryBlue} !important;
  }
`
