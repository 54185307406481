export const POLSteps = [
  {
    number: 1,
    text: `Payment orchestration layers, or POLs, are a new way to easier and less stressful digital payments, according to payment experts and analysts. Merchants are readying seamless checkout and uniform digital payments. To accommodate the payment requirements demanded by different countries merchants in the past had to partner with many payment services - costly and time consuming. POLs are convenient and frictionless with any external payment processors, fulfilling requirements that merchants satisfy all international payment procedures and don't see
    shoppers logging off from almost-completed transactions. Until now, settlement options have been governed by individual markets and their local payment companies. POLs, instead, can connect many payment services at once using open interfaces and allowing new connections to be added later. POLs can minimize interruptions and latency while automating safe, streamlined payments like routing and authorizations by taking advantage of cloud-based scalability and security.`,
  },
  {
    number: 2,
    text: `Convenient payment may satisfy customers around the world. POLs may become essential for frictionless and safe digital payments. Getting the right mix of PSPs is important for a frictionless flow and uniform experience - augmenting online payments and helping company revenue.
    Payment flow includes billing, fulfilment and order entry systems. With thousands of transactions per second on different platforms, chances of slow and even failed transactions are high. POLs automate and streamline the process by switching transactions from a declining PSP to an alternative one - preventing customer abandonment.`,
  },
  {
    number: 3,
    text: `Regulatory compliance and rules are simplified as are the number of points where changes are needed. POPs offer compliant payment data protection and customer data tokenization to ensure the safety of customers’ data. POLs can shift technical resources to new value-added functionality and ultimately support business objectives.`,
  },
  {
    number: 4,
    text: `Regardless of the effects on the payment industry, some companies hesitate to change - preferring to stay with the same card processors. The digital- payments industry must apply platform management, reduce demand for constraint resources and, eventually, create new-added value to businesses.`,
  },
]
