import styled from "styled-components"
import { colors } from "../../../utils/styles"

export const PaymentOrchLayerSectionWrapper = styled.section`
  background-color: #e4e4e4;
  ul {
    list-style: none;
    li {
      font-size: 0.9rem;
      &::before {
        content: "✓";
        margin-right: 14px;
        margin-left: -25px;
        color: ${colors.secondaryBlue2};
      }
    }
  }
`
