import React from "react"
import Layout from "../components/Global/Layout/Layout"
import HeroSection from "../components/PaymentOrchPage/HeroSection/HeroSection"
import PaymentOrchLayerSection from "../components/PaymentOrchPage/PaymentOrchLayer/PaymentOrchLayerSection"
import FormSection from "../components/PaymentOrchPage/FormSection/FormSection"
import SEO from "../components/Global/SEO/seo"

const PaymentOrchestrationLayerPage = () => (
  <Layout>
    <SEO page="what-is-payment-orchestration" />
    <HeroSection />
    <PaymentOrchLayerSection />
    <FormSection />
  </Layout>
)

export default PaymentOrchestrationLayerPage
