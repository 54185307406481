import React from "react"
import { StyledInput } from "./ApInput.styles"
import { Form } from "react-bootstrap"

const ApInput = ({ label, type, placeholder, supportText, as, required, name }) => {
  return (
    <StyledInput controlId="formBasicEmail">
      {label && (
        <Form.Label>
          {label} {required && "*"}
        </Form.Label>
      )}
      <Form.Control
        type={type}
        placeholder={placeholder ? placeholder : ""}
        required={required}
        name={name}
        as={as}
      />
      {supportText && (
        <Form.Text className="text-muted">{supportText}</Form.Text>
      )}
    </StyledInput>
  )
}

export default ApInput
